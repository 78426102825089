import { Link, StaticQuery, graphql } from 'gatsby';
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import ButtonNotUnderlined from '../../components/ButtonNotUnderlined';

const CaseStudyLinksContainer = styled.div``;

const CaseStudyItemContainer = styled.div`
  max-width: 265px;
  margin-bottom: 30px;
  cursor: pointer;
`;

const CaseStudyTitle = styled(motion.div)`
  font-family: 'DM Serif Text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #404040;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-family: 'DM Serif Text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #a0a0a0;
`;

const CaseStudyDescription = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #9f9f9f;
  margin-top: 10px;
`;

const CaseStudyCta = styled.div`
  margin-top: 20px;
`;

const CaseStudiesContainer = styled(motion.div)``;

const CaseStudyItemOuterContainer = styled(motion.div)``;

const container = {
  hidden: {
    opacity: 0,
    scale: 1,
    transition: {
      delay: 0.2,
      when: 'afterChildren',
      staggerChildren: 0.1,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.2,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
  transition: {
    duration: 0.5,
  },
};

const itemReverse = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
  transition: {
    duration: 0.5,
  },
};

export class CaseStudyLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
      menuUnloading: false,
    };
  }

  componentDidMount = () => {
    if (!this.state.menuUnloading) {
      this.setState({ visible: true });
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.state.menuUnloading) {
      if (nextProps.menuUnloading) {
        this.setState({
          menuUnloading: nextProps.menuUnloading,
          visible: false,
        });
      }
    }
  };

  render() {
    return (
      <CaseStudyLinksContainer>
        <Title>{this.props.data.case_study_section_title.text}</Title>
        <CaseStudiesContainer
          variants={container}
          initial="hidden"
          animate={this.state.visible ? 'visible' : 'hidden'}
        >
          {this.props.data.case_studies.map((caseStudyItem, index) => (
            <CaseStudyItemOuterContainer
              variants={this.state.menuUnloading ? itemReverse : item}
              key={index}
            >
              <CaseStudyItemContainer>
                <a href={caseStudyItem.cta_link.url}>
                  <CaseStudyTitle
                    whileHover={{
                      color: this.props.data.highlight_color,
                    }}
                    transition={{
                      duration: 0.5,
                    }}
                  >
                    {caseStudyItem.title.text}
                  </CaseStudyTitle>
                </a>
                <CaseStudyDescription>
                  {caseStudyItem.description.text}
                </CaseStudyDescription>
                <CaseStudyCta>
                  <ButtonNotUnderlined
                    link={caseStudyItem.cta_link.url}
                    arrowColor={caseStudyItem.cta_accent_color}
                    buttonText={caseStudyItem.cta_text.text}
                    color={'#fff'}
                    fontSize={'14px'}
                  />
                </CaseStudyCta>
              </CaseStudyItemContainer>
            </CaseStudyItemOuterContainer>
          ))}
        </CaseStudiesContainer>
      </CaseStudyLinksContainer>
    );
  }
}

export default CaseStudyLinks;
