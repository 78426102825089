/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import fonts from '../fonts/fonts.css';
import reset from '../utils/resets.css';
import withLocation from '../utils/withLocation';
import Header from './header';
import Footer from './footer';
import './layout.css';

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 100;
  height: ${props => props.barHeight};
  position: ${props => props.menu_background};
  @media (max-width: 735px) {
    height: 60px;
  }
`;

const BlurOverlay = styled(motion.div)`
  z-index: 5;
  height: 100vh;
  width: 100vw;
  position: fixed;
`;

const CookiesDesktop = styled.div`
  display: block;
  width: 100vw;
  overflow: hidden;
  @media (max-width: 865px) {
    display: none;
  }
`;

const CookiesMobile = styled.div`
  display: none;
  width: 100vw;
  overflow: hidden;
  @media (max-width: 865px) {
    display: block;
  }
`;

const Layout = ({
  children,
  menu_background,
  header_items_color,
  display_review_bar,
  logo,
  fixed_mobile_header_items_color,
  mobile_logo,
  menu_logo,
  mobile_header_items_color,
  fixed_background_transparent_mode,
  fixed_background_color,
  fixed_menu_items_color,
  logo_link,
  review_link,
  fixed_menu_logo,
  fixed_mobile_logo,
  header_background_color,
  footer_logo,
  location,
}) => {
  const [active, setActive] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allPrismicMenuBar {
        nodes {
          data {
            review_bar_text {
              text
            }
            review_bar_link {
              url
              slug
              target
            }
            logo_link {
              url
              slug
              target
            }
            star_count
            menu_items {
              menu_item
              menu_link {
                slug
                url
                target
              }
            }
          }
        }
      }
      allPrismicMainMenu {
        nodes {
          data {
            highlight_color
            background_color
            squiggle_color
            body {
              ... on PrismicMainMenuBody1stLevel {
                slice_type
                id
                primary {
                  show_menu
                  link_index
                  summary
                  link_text
                  nav_link {
                    url
                  }
                }
              }
              ... on PrismicMainMenuBody2ndLevel {
                slice_type
                id
                primary {
                  link_index
                  link_text
                  nav_link {
                    url
                  }
                }
                items {
                  third_level_link {
                    url
                  }
                  third_level_link_text
                }
              }
            }
          }
        }
      }
      allPrismicFooter {
        nodes {
          data {
            explore_links {
              link_text {
                text
              }
              explore_link {
                url
                target
              }
            }
            newsletter_title {
              text
            }
            newsletter_thank_you_message {
              text
            }
            explore_title {
              text
            }
            company_title {
              text
            }
            company_links {
              link_text {
                text
              }
              link {
                url
                target
              }
            }
            legal_title {
              text
            }
            legal_links {
              link_text {
                text
              }
              link {
                url
                target
              }
            }
            find_us_title {
              text
            }
            telephone_number {
              text
            }
            address_details {
              text
            }
            address_details_two {
              text
            }
            facebook_link {
              url
              target
            }
            linkedin_link {
              url
              target
            }
            instagram_link {
              url
              target
            }
          }
        }
      }
    }
  `);

  const handleMenuLoading = () => {
    setActive(!active);
  };
  {
    useEffect(props => {
      var el = document.querySelectorAll('[href^="#"]');
      for (var i = 0; i < el.length; i++) {
        el[i].addEventListener(
          'click',
          function(event, i) {
            event.preventDefault();
            if (
              document.querySelector(this.getAttribute('href')) !== undefined
            ) {
              let topOfElement =
                document.querySelector(this.getAttribute('href')).offsetTop -
                '30';
              window.scroll({ top: topOfElement, behavior: 'smooth' });
            }
          },
          false
        );
      }

      if (window.location.hash) {
        if (document.querySelector(window.location.hash) !== undefined) {
          let topOfElement =
            document.querySelector(window.location.hash).offsetTop - '30';
          window.scroll({ top: topOfElement, behavior: 'smooth' });
        }
      }
    });
  }
  return (
    <>
      <React.Fragment>
        <Helmet>
          <script
            async
            type="text/javascript"
            src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=P5zdyu"
          ></script>
          <script
            async
            type="module"
            src="https://cdn.jsdelivr.net/npm/@justinribeiro/lite-youtube@0.6.2/lite-youtube.js"
          ></script>
          {/*
          <script>{`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WMPLHW6');
        `}</script>

          <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WMPLHW6"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>

          <script>{`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '690072231187746');
            fbq('track', 'PageView');
            `}</script>
          <noscript>{`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=690072231187746&ev=PageView&noscript=1"
            />`}</noscript>
            */}
        </Helmet>
        {menu_background === 'true' ? (
          <HeaderContainer
            menu_background={
              menu_background && menu_background === 'true' ? 'block' : 'block'
            }
            barHeight={display_review_bar === 'true' ? '160px' : '140px'}
            header_background_color_var={
              header_background_color ? header_background_color : '#ffffff'
            }
          >
            <Header
              siteTitle={data.site.siteMetadata.title}
              headerData={data.allPrismicMenuBar.nodes[0].data}
              menuData={data.allPrismicMainMenu.nodes[0].data}
              menu_background={menu_background}
              header_items_color={header_items_color}
              review_link={data.allPrismicMenuBar.nodes[0].data.review_bar_link}
              display_review_bar={display_review_bar}
              logo={logo}
              menu_logo={menu_logo}
              fixed_mobile_logo={fixed_mobile_logo}
              mobile_logo={mobile_logo}
              logo_link={data.allPrismicMenuBar.nodes[0].data.logo_link}
              mobile_header_items_color={mobile_header_items_color}
              fixed_mobile_header_items_color={fixed_mobile_header_items_color}
              fixed_background_transparent_mode={
                fixed_background_transparent_mode
              }
              fixed_background_color={fixed_background_color}
              fixed_menu_items_color={fixed_menu_items_color}
              fixed_menu_logo={fixed_menu_logo}
              handleMenuLoading={handleMenuLoading}
            />
          </HeaderContainer>
        ) : (
          <HeaderContainer
            menu_background={
              menu_background && menu_background === 'true' ? 'block' : 'block'
            }
            barHeight={display_review_bar === 'true' ? '160px' : '140px'}
            header_background_color={'#ffffff0'}
          >
            <Header
              siteTitle={data.site.siteMetadata.title}
              headerData={data.allPrismicMenuBar.nodes[0].data}
              menuData={data.allPrismicMainMenu.nodes[0].data}
              menu_background={menu_background}
              header_items_color={header_items_color}
              display_review_bar={display_review_bar}
              logo={logo}
              review_link={data.allPrismicMenuBar.nodes[0].data.review_link}
              menu_logo={menu_logo}
              logo_link={data.allPrismicMenuBar.nodes[0].data.logo_link}
              mobile_logo={mobile_logo}
              fixed_mobile_logo={fixed_mobile_logo}
              mobile_header_items_color={mobile_header_items_color}
              fixed_background_transparent_mode={
                fixed_background_transparent_mode
              }
              fixed_background_color={fixed_background_color}
              fixed_menu_items_color={fixed_menu_items_color}
              fixed_menu_logo={fixed_menu_logo}
              handleMenuLoading={handleMenuLoading}
            />
          </HeaderContainer>
        )}

        <div
          style={{
            margin: `0 auto`,
            paddingTop: 0,
            height: '100%',
            width: '100%',
            maxWidth: '100vw',
          }}
        >
          {active ? (
            <BlurOverlay
              animate={
                active
                  ? { opacity: '1', backgroundColor: '#fff' }
                  : { opacity: '0' }
              }
              transition={{ delay: 0.6, duration: 0.2 }}
            />
          ) : null}
          <main>{children}</main>
          <CookiesDesktop>
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              cookieName="cookieAcception"
              style={{
                fontFamily: 'DM Sans',
                background: '#212121',
                overflow: 'hidden',
                lineHeight: '18px',
                marginBottom: '0px',
              }}
              buttonStyle={{
                backgroundColor: '#212121',
                color: '#fff',
                fontSize: '13px',
                fontFamily: 'DM Sans',
                fontWeight: 800,
              }}
              expires={15000}
            >
              We use cookies to analyse our traffic, improve your experience and
              to personalise ads. This helps us to do what we do for our
              clients.{' '}
              <span style={{ fontWeight: 'bold' }}>
                <a href="/fine-print/?section=cookies" target="_blank">
                  View our cookie policy here.
                </a>
              </span>
            </CookieConsent>
          </CookiesDesktop>
          <CookiesMobile>
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              cookieName="cookieAcception"
              style={{
                fontFamily: 'DM Sans',
                background: '#212121',
                overflow: 'hidden',
                lineHeight: '18px',
                fontSize: '12px',
                alignItems: 'center',
                marginBottom: '0px',
              }}
              buttonStyle={{
                backgroundColor: '#212121',
                color: '#ffffff',
                fontSize: '13px',
                fontFamily: 'DM Sans',
                fontWeight: 800,
                marginBottom: '15px',
              }}
              expires={15000}
            >
              We use cookies to analyse our traffic, improve your experience and
              to personalise ads. This helps us to do what we do for our
              clients.{' '}
              <span style={{ fontWeight: 'bold' }}>
                <a href="/fine-print/?section=cookies" target="_blank">
                  View our cookie policy here.
                </a>
              </span>
            </CookieConsent>
          </CookiesMobile>

          <Footer
            footerData={data.allPrismicFooter.nodes[0].data}
            footer_logo={footer_logo}
          />
        </div>
      </React.Fragment>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export const query = graphql`
  fragment Blog on PrismicBlog {
    id
    uid
    url
    data {
      blog_title {
        text
      }
      featured_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      featured_video {
        url
      }
      seo_title {
        text
      }
      seo_description {
        text
      }
      post_author {
        text
      }
      author_bio {
        text
      }
      post_date
      post_category
      post_color_theme
      post_category
      logo
      fixed_logo
      mobile_logo
      fixed_mobile_logo
      menu_logo
      display_review_bar
      subtitle {
        text
      }
      open_graph_title {
        text
      }
      open_graph_description {
        text
      }
      open_graph_type {
        text
      }
      open_graph_image {
        url
      }
      author_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      body {
        ... on PrismicBlogBodyHeading {
          ...BlogHeading
        }
        ... on PrismicBlogBodyParagraphText {
          ...BlogParagraphText
        }
        ... on PrismicBlogBodyYoutubeVideo {
          ...BlogYoutubeVideo
        }
        ... on PrismicBlogBodyAnchorsBlock {
          ...BlogAnchorsBlock
        }
        ... on PrismicBlogBodyAnchor {
          ...BlogAnchor
        }
        ... on PrismicBlogBodySingleImage {
          ...BlogSingleImage
        }
        ... on PrismicBlogBodyServicesBlock {
          ...ServicesBlock
        }
        ... on PrismicBlogBodyNewsletterSignupForm {
          ...BlogNewsletterSignup
        }
        ... on PrismicBlogBodyTwoImageOffsetBlock {
          ...TwoImageOffset
        }
        ... on PrismicBlogBodyDivider {
          ...Divider
        }
        ... on PrismicBlogBodyQuote {
          ...BlogQuote
        }
        ... on PrismicBlogBodyCta {
          ...BlogCta
        }
        ... on PrismicBlogBodyCustomHtml {
          ...BlogHtml
        }
        ... on PrismicBlogBodyLeftTextRightImage {
          ...BlogLeftTextRightImage
        }
        ... on PrismicBlogBodyRightTextLeftImage {
          ...BlogRightTextLeftImage
        }
        ... on PrismicBlogBodyBlankSpace {
          ...BlogBodyBlankSpace
        }
        ... on PrismicBlogBodyStandOutBlock {
          ...BlogBodyStandOutBlock
        }
      }
    }
  }
`;

export default withLocation(Layout);
