import { css } from '@emotion/core';

export const buttonReset = css`
  border: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  font-family: 'DM Sans';
`;
